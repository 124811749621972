/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

import "./layout.scss";
import logo from "../images/scuba-icon.svg";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<header>
				<Link to="/">
					<img src={logo} alt="Logo" />
				</Link>

				<h1>
					<Link to="/">{data.site.siteMetadata.title}</Link>
				</h1>
			</header>
			<main>{children}</main>
			<footer></footer>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
